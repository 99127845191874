.container {
    position: relative;
    /*max-width: 571px;*/
    /*width: 100%;*/
    cursor: pointer;
}

.container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    background: #ffffff;
    opacity: 0.6;
}

.swiper-pagination-bullet-active {
    opacity: 1;
}

.slider-icons {
    position: absolute;
    top: 27px;
    right: -3%;
    transform: translateX(-50%);
    display: flex;
    gap: 14px;
}

